<template>
  <div class="card-zip">
    <template v-if="showIcon">
      <z-icon name="ZIP" size="16px" />Guías.ZIP
    </template>
    <template v-else>
      <loading-spinner class="loading-spinner" />Descargando etiquetas...
    </template>
  </div>
</template>

<script>
export default {
  name: "CardZip",
  props: {
    showIcon: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="scss" scoped>
.card-zip {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: $solitude-3;
  padding: 20px;
  border-radius: 10px;
  margin-top: 1.5rem;
  font-size: 12px;
  font-weight: 600;
}
.loading-spinner {
  height: 18px;
}
</style>
