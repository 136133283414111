<template>
  <div>
    <z-delivery-creation-modal
      v-if="!showConfirmation && !showWaitingModal"
      title="Crear guía"
      subtitle="Recuerda que solo puedes solicitar servicios Same day antes de las 11:00 am"
      :hide-close="true"
      :service-type="packageType"
      :enable-create="enableCreate"
      :creating="loading"
      @cancel="goPreviousPage"
      @hide="goPreviousPage"
      @create="createParcels"
    >
      <quick-message
        class="mt-3"
        title="Error"
        :message="errorMessage"
        :show.sync="showError"
        type="error"
        with-icon
      />
      <div class="wrapper d-md-flex">
        <div class="w-100 left-row">
          <delivery-mode delivery-multiple />
          <address-data-form delivery-multiple variant="origin" />
          <upload-template
            :total.sync="total"
            :num-parcels.sync="numParcels"
            :parcels.sync="parcels"
            @error="handleError"
          />
        </div>
        <div class="w-100 right-row">
          <div class="d-md-flex">
            <download-template class="download-template" />
            <package-info :content="content" class="package-info" />
          </div>
          <package-payment-method>
            <package-payment-info :info-items="infoItems" />
          </package-payment-method>
        </div>
      </div>
    </z-delivery-creation-modal>
    <package-confirmation-modal
      v-else-if="showConfirmation && !showWaitingModal"
      :show.sync="showConfirmation"
      :address="confirmationAddress"
      no-close-on-backdrop
      no-close-on-esc
      variant="parcels"
      @get-shipping-label="getShippingLabels"
      @update:show="goPreviousPage"
    >
      <card-zip />
    </package-confirmation-modal>
    <package-waiting-modal v-else />
  </div>
</template>

<script>
import CardZip from "./CardZip";
import DownloadTemplate from "./DownloadTemplate";
import UploadTemplate from "./UploadTemplate/UploadTemplate";
import AddressDataForm from "../PackageCreation/AddressDataForm";
import PackageConfirmationModal from "../PackageCreation/PackageConfirmationModal";
import PackagePaymentInfo from "../PackageCreation/PackagePaymentInfo";
import PackagePaymentMethod from "../PackageCreation/PackagePaymentMethod";
import PackageWaitingModal from "../PackageCreation/PackageWaitingModal";
import DeliveryMode from "@/app/components/PackageCreation/DeliveryMode";
import PackageInfo from "@/app/components/PackageCreation/PackageInfo";
import ZDeliveryCreationModal from "@/app/components/ZDeliveryCreationModal";

import ServiceType from "@zubut/common/src/constants/services/type";

const MODULE_NAME = "parcel/multiple";

export default {
  name: "MultiplePackageCreation",
  components: {
    CardZip,
    DownloadTemplate,
    UploadTemplate,
    AddressDataForm,
    PackageConfirmationModal,
    PackagePaymentInfo,
    PackagePaymentMethod,
    PackageWaitingModal,
    DeliveryMode,
    PackageInfo,
    ZDeliveryCreationModal
  },
  data() {
    return {
      showError: false,
      errorMessage: "",
      packageType: ServiceType.NUM_PARCEL_DELIVERY,
      content: {
        title: "Etiqueta",
        paragraphs: [
          "Generamos un ZIP con tus etiquetas que podras descargar una vez efectuando el pago.",
          "Imprime tus guías y pégalas en tus paquetes, recuerda tenerlos listos antes de que llegue el mensajero."
        ],
        iconName: "ZPL"
      },
      total: 0,
      numParcels: 0,
      loading: false,
      parcels: [],
      showConfirmation: false,
      confirmationAddress: {
        address: "",
        name: ""
      },
      zipObjectKey: "",
      showWaitingModal: false
    };
  },
  computed: {
    enableCreate() {
      return this.isFormValid && this.numParcels > 0 ? true : false;
    },
    formattedTotal() {
      return `${this.total.toFixed(2)}  `;
    },
    infoItems() {
      const infoItems = [
        { title: "Total", data: this.formattedTotal },
        { title: "No. de guías", data: this.numParcels }
      ];
      return infoItems;
    },
    isFormValid() {
      return this.$store.getters[`${MODULE_NAME}/isFormValid`];
    },
    destinationOrigin() {
      return this.$store.getters[`${MODULE_NAME}/getDestinationOrigin`];
    }
  },
  methods: {
    goPreviousPage() {
      this.$router.go(-1);
    },
    createParcels() {
      this.loading = true;
      this.$store
        .dispatch(`${MODULE_NAME}/bulkRequest`, this.parcels)
        .then(res => {
          this.zipObjectKey = res.zipObjectKey;
          this.showConfirmation = true;
          this.confirmationAddress.address = this.destinationOrigin.address.address;
          this.confirmationAddress.name = this.destinationOrigin.name;
        })
        .catch(err => {
          let message = err.message;
          if (err.name === "Not enough funds in the wallet") {
            message = "No cuentas con suficientes fondos en tu Wallet";
          }
          this.errorMessage = message;
          this.showError = true;
          this.$captureError(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getShippingLabels() {
      this.showWaitingModal = true;
      this.$store
        .dispatch(`${MODULE_NAME}/downloadParcelBulkZpls`, this.zipObjectKey)
        .then(res => {
          const blobUrl = URL.createObjectURL(res);
          const link = document.createElement("a");
          link.href = blobUrl;
          link.download = "Guías.zip";
          link.click();
        })
        .catch(err => {
          this.$captureError(err);
        })
        .finally(() => {
          this.showWaitingModal = false;
          this.goPreviousPage();
        });
    },

    handleError(message) {
      this.showError = true;
      this.errorMessage = message;
    }
  }
};
</script>

<style lang="scss" scoped>
.download-template {
  margin-right: 16px;
}
.wrapper {
  margin-top: 14px;
}
.left-row > div:not(:first-child) {
  margin-top: 14px;
}
.right-row > div:not(:first-child) {
  margin-top: 14px;
}
@include media-breakpoint-down(sm) {
  .right-row > div:first-of-type {
    margin-top: 14px;
  }
  .package-info {
    margin-top: 14px;
  }
}
@include media-breakpoint-up(xl) {
  .wrapper {
    gap: 32px;
  }
}
</style>
