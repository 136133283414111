<template>
  <div class="file-status">
    <loading-spinner v-if="loading" class="loading-spinner" />
    <z-icon
      v-else-if="iconName"
      size="16"
      :name="iconName"
      :color="iconName === 'Check' ? 'green' : 'red'"
    />
    <span class="text-dim-gray">{{ fileStatusText }}</span>
  </div>
</template>

<script>
export default {
  name: "FileStatus",
  props: {
    fileStatusText: {
      type: String,
      required: true
    },
    iconName: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.file-status {
  display: flex;
  align-items: center;
  gap: 12px;
  span {
    font-size: 12px;
    font-weight: 500;
  }
}
.loading-spinner {
  height: 18px;
}
</style>
