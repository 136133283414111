<template>
  <div>
    <template v-if="!isErrorServer">
      <p class="error-title">Verífica tu archivo</p>
      <p class="error-subtitle">
        Detectamos algunos errores, corrige el archivo y súbelo de nuevo
      </p>

      <div class="errors-list">
        <p v-for="(error, index) in errorsList" :key="index">
          {{ error.message }}
        </p>
      </div>
    </template>
    <template v-else>
      <p class="error-title">Ocurrió un error en el servidor</p>
    </template>
  </div>
</template>

<script>
export default {
  name: "ListErrors",
  props: {
    errorsList: {
      type: Array,
      required: true
    },
    isErrorServer: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.error-title {
  font-weight: 600;
  color: black;
  margin: 0;
}
.error-subtitle {
  font-weight: 600;
  color: $comet;
  font-size: 12px;
}
.errors-list {
  max-height: 200px;
  overflow-y: scroll;
}
.errors-list > p {
  margin: 0;
  font-size: 12px;
}

@media (max-width: 575px) {
  .errors-list > p {
    margin-bottom: 12px;
  }
}
</style>
