<template>
  <z-modal
    :show="show"
    no-close-on-backdrop
    no-close-on-esc
    centered
    hide-close
    size="md"
    body-class="p-5 rounded"
  >
    <p class="text-xl font-weight-bold">Generando etiquetas</p>
    <p>
      Espera unos segundos, estamos generando tus etiquetas.
    </p>
    <card-zip :show-icon="false" />
    <z-button
      expanded
      variant="primary"
      class="mt-4 p-3"
      :loading="loading"
      :disabled="true"
    >
      Descargar etiqueta
    </z-button>
  </z-modal>
</template>

<script>
import ZModal from "@zubut/common/src/components/ZModal";
import CardZip from "../MultiplePackagesCreation/CardZip";

export default {
  name: "PackageWaitingModal",
  components: {
    ZModal,
    CardZip
  },
  props: {
    show: {
      type: Boolean,
      required: true,
      default: true
    }
  }
};
</script>

<style lang="scss" scoped></style>
