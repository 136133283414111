<template>
  <div class="file-info">
    <template v-if="showFileStatus">
      <file-card
        :is-file-with-errors="isFileWithErrors"
        :file="file"
        v-on="$listeners"
      />
      <file-status
        :file-status-text="fileStatusText"
        :loading="loading"
        :icon-name="iconName"
      />
      <list-errors
        v-if="isFileWithErrors"
        :errors-list="errorsList"
        :is-error-server="isErrorServer"
      />
    </template>
  </div>
</template>

<script>
import FileCard from "./FileCard";
import FileStatus from "./FileStatus";
import ListErrors from "./ListErrors.vue";
export default {
  name: "FileInfo",
  components: { FileCard, FileStatus, ListErrors },
  props: {
    errorsList: {
      type: Array,
      required: true,
      default: () => []
    },
    file: { type: File, required: false, default: null },
    fileStatusText: {
      type: String,
      required: true
    },
    iconName: {
      type: String,
      required: true
    },
    isErrorServer: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      required: true
    },
    showFileStatus: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    isFileWithErrors() {
      return this.errorsList.length > 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.file-info > div {
  margin-top: 14px;
}

@media (min-width: 576px) {
  .file-info {
    margin-left: 32px;
  }
}
</style>
