<template>
  <package-card :content="content" class="package-card">
    <div>
      <a :href="url" target="_blank"
        ><z-button class="z-button"
          ><span>Descargar Plantilla</span></z-button
        ></a
      >
    </div>
  </package-card>
</template>

<script>
import PackageCard from "../../components/PackageCreation/PackageCard.vue";
export default {
  name: "DownloadTemplate",
  components: { PackageCard },
  data() {
    return {
      content: {
        title: "Plantilla CSV",
        paragraphs: [
          "Descarga esta plantilla y llénala con los datos de tus paquetes.",
          "Cárga el archivo y presiona crear guías."
        ],
        iconName: "CsvColor"
      },
      url:
        "https://docs.google.com/spreadsheets/d/e/2PACX-1vTKk-Eyc3m5pE7e6sLjndlHeU5Ho6rOnKG1_XYsK5C0KttE0fj0GELmo0pOyQ__Kw/pub?gid=1376782123&single=true&output=csv"
    };
  }
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
.package-card {
  background: $cosmic-latte;
}

.z-button {
  width: 100%;
  background: $white-ice;
  color: $watercourse;

  &:hover {
    background: $iceberg;
  }
  span {
    font-weight: 600;
  }
}
</style>
