<template>
  <div class="upload-container">
    <header-container @parseFromCsv="parseFromCsv($event)" />
    <file-info
      :file="file"
      :show-file-status="showFileStatus"
      :loading="loading"
      :file-status-text="fileStatusText"
      :icon-name="iconName"
      :is-error-server="isErrorServer"
      :errors-list="errorsList"
      @reset-state="resetState"
    />
  </div>
</template>

<script>
import FileInfo from "./FileInfo";
import HeaderContainer from "./HeaderContainer";

const MODULE_NAME = "parcel/multiple";

export default {
  name: "UploadTemplate",
  components: { FileInfo, HeaderContainer },
  data() {
    return this.initialState();
  },

  methods: {
    parseFromCsv(event) {
      this.file = event.currentTarget.files[0];

      this.showFileStatus = true;

      this.loading = true;
      this.fileStatusText = "Cargando/Analizando...";
      this.isErrorServer = false;

      this.$store
        .dispatch(`${MODULE_NAME}/parseFromCsv`, this.file)
        .then(res => {
          this.changeStateInThen(res);
          this.updateEmitters(res);
        })
        .catch(err => {
          this.changeStateInCatch(err);
          this.resetEmitters();
        })
        .finally(() => (this.loading = false));

      event.target.value = "";
    },
    changeStateInThen(res) {
      this.fileStatusText = "¡Carga exitosa!";
      this.iconName = "Check";
      this.errorsList = [];
      this.parcels = res.parcels;
      this.parcels.forEach(parcel => {
        if (parcel.additional.externalId)
          parcel.additional.externalId = parcel.additional.externalId.toString();
      });
    },
    changeStateInCatch(err) {
      this.iconName = "Close";
      this.fileStatusText = `Errores detectados: ${this.errorLength(err)}`;
      this.errorsList = this.errorMessage(err);
      this.parcels = [];
      if (err.statusCode >= 500) {
        this.$emit("error", "Ocurrió un error, intenta de nuevo más tarde.");
        this.isErrorServer = true;
      }
    },
    errorLength(err) {
      return err.message instanceof Array ? err.message.length : 1;
    },
    errorMessage(err) {
      return err.message instanceof Array
        ? err.message
        : [{ message: err.message }];
    },
    initialState() {
      return {
        file: null,
        showFileStatus: false,
        loading: false,
        parcels: [],
        fileStatusText: "",
        iconName: "",
        errorsList: [],
        isErrorServer: false
      };
    },
    resetState() {
      this.file = this.initialState().file;
      this.showFileStatus = this.initialState().showFileStatus;
      this.loading = this.initialState().loading;
      this.parcels = this.initialState().parcels;
      this.fileStatusText = this.initialState().fileStatusText;
      this.iconName = this.initialState().iconName;
      this.errorsList = this.initialState().errorsList;
      this.isErrorServer = this.initialState().isErrorServer;

      this.resetEmitters();
    },
    resetEmitters() {
      this.$emit("update:total", 0);
      this.$emit("update:numParcels", 0);
    },
    updateEmitters(res) {
      this.$emit("update:total", res.totalCost);
      this.$emit("update:numParcels", res.totalParcels);
      this.$emit("update:parcels", this.parcels);
    }
  }
};
</script>

<style lang="scss" scoped>
.upload-container {
  padding: 20px 20px 20px 12px;
  border: 1px solid $zircon;
  border-radius: 10px;
}
</style>
