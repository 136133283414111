<template>
  <div class="header-container align-content">
    <div class="title">
      <z-icon name="Csv" size="20" />
      <span class="title--text">Subir plantilla CSV</span>
    </div>
    <input-button
      variant="primary"
      class="input-button"
      @parseFromCsv="$emit('parseFromCsv', $event)"
    >
      <div class="button-content align-content">
        <z-icon
          name="Upload"
          size="18"
          view-box="0 0 24 20"
          :variant="variant"
        />
        <span>Subir archivo</span>
      </div>
    </input-button>
  </div>
</template>

<script>
import InputButton from "@zubut/common/src/components/InputButton.vue";
export default {
  name: "HeaderContainer",
  components: { InputButton },
  computed: {
    isDeliveryFormValid() {
      return this.$store.getters["parcel/multiple/isFormValid"];
    },
    variant() {
      return this.isDeliveryFormValid === false ? "normal" : "active";
    }
  }
};
</script>

<style lang="scss" scoped>
span {
  font-weight: 600;
}
.align-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title {
  display: flex;
  gap: 12px;
  &--text {
    font-size: 14px;
  }
}
.button-content {
  gap: 12px;
}

@media (max-width: 500px) {
  .header-container {
    flex-direction: column;
  }
  .input-button {
    margin-top: 14px;
  }
}
</style>
