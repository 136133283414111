<template>
  <div
    v-if="file"
    class="file-card"
    :class="{ 'file-card--errors': isFileWithErrors }"
  >
    <span>{{ file.name }}</span>
    <div class="circle">
      <z-icon
        size="12"
        name="Close"
        style="cursor:pointer"
        @click.native="resetState"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "FileCard",
  props: {
    file: { type: File, required: false, default: null },
    isFileWithErrors: { type: Boolean, required: true }
  },
  methods: {
    resetState() {
      this.$emit("reset-state");
    }
  }
};
</script>

<style lang="scss" scoped>
.circle {
  display: flex;
  border: 2px solid $comet;
  border-radius: 50%;
  padding: 1px;
}
.file-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $solitude-3;
  padding: 1em;
  border-radius: 6px;

  &--errors {
    outline: 2px solid $opium;
  }
}
</style>
