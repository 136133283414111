<template>
  <label :class="[variant, { disabled: isDesabled }]">
    <input
      v-show="false"
      type="file"
      name="file"
      accept="text/csv"
      :disabled="isDesabled"
      @change="$emit('parseFromCsv', $event)"
    />

    <slot />
  </label>
</template>

<script>
export default {
  name: "InputButton",
  props: {
    variant: {
      type: String,
      default: "primary",
      validator: value => ["primary"].indexOf(value) !== -1
    }
  },
  computed: {
    isDeliveryFormValid() {
      return this.$store.getters["parcel/multiple/isFormValid"];
    },
    isDesabled() {
      return this.isDeliveryFormValid === false ? true : false;
    }
  }
};
</script>

<style lang="scss" scoped>
label {
  margin: 0;
  cursor: pointer;
  font-size: 0.875em;
  border-radius: 6px;
  padding: 0.7em 0.9em;
}

label.primary {
  background-color: var(--primary-bg);
  color: var(--primary);

  &:hover {
    background-color: var(--primary-bg);
    text-decoration: none;
  }
  &.disabled {
    background: transparent;
    color: $comet;
    cursor: default;
  }
}
</style>
